import { currentLocaleUrl } from './util';

/**
 * postLoginCallback
 */
export const postLoginCallback = (res: any, router: any, cookie?: any, network?: any, session?: boolean) => {
    const { state, data } = res;
    const { uauthCode, type } = data || {};
    if (Number(state) === 1) {
        // data.commentToken = "eyJhbGciOiJIUzI1NiJ9.NTA2YjE1Mjkz.SsEJnCcidvmSpHJvEFxQJ1Y5QKWO_NqV5__9fp4Gx90"
        localStorage.setItem('Authorization', uauthCode);
        localStorage.setItem('userInfo', JSON.stringify(data));
        // console.log(1111, 'JSON.stringify(data)', JSON.stringify(data));

        const { nickname, uid, icon, commentToken, commentAvatar, commentObjectId, commentMailMd5 } = data || {};
        const userKey = 'WALINE_USER';
        const storage = {
            display_name: nickname,
            email: uid,
            remember: true,
            token: commentToken,
            avatar: icon || commentAvatar,
            objectId: commentObjectId,
            mailMd5: commentMailMd5,
        };

        localStorage.setItem(userKey, JSON.stringify(storage));

        cookie?.('AUTH_TOKEN', uauthCode, { path: '/', sameSite: true });
        const typeNum = Number(type || 0);
        const redirect = decodeURIComponent(
            session ? sessionStorage.getItem('redirect') || '' : router?.query?.redirect || '',
        );
        switch (typeNum) {
            case 1: // 1: 需要先设置密码
                router.push(`/setPwd`);
                break;
            case 2: // 2: 需要先绑定手机或邮箱并设置密码
                router.replace(`/bindAccount?state=${network}&redirect=${redirect}`);
                break;
            default:
                if (redirect === '/feedback') {
                    router.push('/feedback?login=1');
                } else {
                    window.open(currentLocaleUrl(redirect || '/'), '_self');
                }
                sessionStorage.removeItem('redirect');
                break;
        }
    } else {
        if (network) {
            router.replace('/login');
        }
    }
};
