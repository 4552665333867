import React, { useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { useRouter } from 'next/router';
import { postLoginApi } from '@/services/auth';
import AuthCode from '@/components/public/AuthCode';
import { useCookies } from 'react-cookie';
import { postLoginCallback } from '@/utils/login';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';

const { pleaseEnter, pleaseEnterCorrect } = formUtils;
const { Password } = Input;

const Email = () => {
    const [_cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    const form = useRef<FormInstance>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [type, setType] = useState<string>('code'); // 默认验证码登录

    const router = useRouter();

    const validateMobile = () => {
        return form?.current?.validateFields(['mail']);
    };

    const onFinish = (values: any) => {
        setLoading(true);
        postLoginApi({ ...values, login_type: type === 'code' ? 4 : 0 })
            .then((res: any) => {
                postLoginCallback(res, router, setCookie);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const toggleLoginType = () => {
        setType(type === 'code' ? 'pwd' : 'code');
    };

    const goFindPwd = () => {
        router.push('/findPwd?type=email');
    };

    return (
        <div className={styles.mobile}>
            <Form
                ref={form}
                size="large"
                autoComplete="off"
                className={styles.form}
                onFinish={onFinish}
                onValuesChange={() => {
                    setDisabled(false);
                }}
            >
                <Form.Item
                    name="mail"
                    rules={[
                        { required: true, message: pleaseEnter(intl.get('BindAccount.Email').d('请输入电子邮箱')) },
                        {
                            type: 'email',
                            message: pleaseEnterCorrect(intl.get('BindAccount.Email').d('请输入正确的电子邮箱')),
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <span className={styles.prefix}>
                                <i className="iconfont icon-mail" />
                            </span>
                        }
                        placeholder={pleaseEnter(intl.get('BindAccount.Email').d('请输入电子邮箱'))}
                    />
                </Form.Item>
                {type === 'code' && (
                    <>
                        <Form.Item
                            style={{ marginBottom: 8 }}
                            name="verificationCode"
                            rules={[
                                {
                                    required: true,
                                    message: pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码')),
                                },
                                {
                                    len: 6,
                                    message: pleaseEnter(
                                        intl.get('Common.SixVerificationCode').d('请输入6位长度的验证码'),
                                    ),
                                },
                            ]}
                        >
                            <Input
                                prefix={
                                    <span className={styles.prefix}>
                                        <i className="iconfont icon-mima" />
                                    </span>
                                }
                                placeholder={pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码'))}
                                maxLength={6}
                                type="number"
                                suffix={<AuthCode validate={validateMobile} />}
                            />
                        </Form.Item>
                        <div className={styles.pwdBtns}>
                            <span />
                            <div className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.PassLogin').d('密码登录')}
                            </div>
                        </div>
                    </>
                )}
                {type === 'pwd' && (
                    <>
                        <Form.Item
                            style={{ marginBottom: 8 }}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')),
                                },
                            ]}
                        >
                            <Password
                                autoComplete="new-password"
                                className={styles.pwd}
                                prefix={
                                    <span className={styles.prefix}>
                                        <i className="iconfont icon-mima" />
                                    </span>
                                }
                                placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                            />
                        </Form.Item>
                        <div className={styles.pwdBtns}>
                            <span className={styles.pwdLogin} onClick={goFindPwd}>
                                {intl.get('Login.ForgetPass').d('忘记密码')}
                            </span>
                            <span className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.VerifyCodeLogin').d('验证码登录')}
                            </span>
                        </div>
                    </>
                )}
                <Form.Item>
                    <Button
                        className={styles.btn}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        disabled={disabled}
                        loading={loading}
                        aria-label="Registration Or Login"
                    >
                        {intl.get('Login.RegisterLog').d('注册/登录')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Email;
