import React, { useEffect, useMemo } from 'react';
import { Button, Divider, Tabs } from 'antd';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Meta, NextImage } from '@/components';
import { Mobile, Email } from '@/components/login';
import { useAppDispatch } from '@/store/hook';
import { getIpPhoneCodeItemAsync } from '@/store/config';
import { withImgData } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';

const Login = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const loginTypeList = useMemo(() => {
        return [
            // { value: 'facebook', title: 'Facebook' },
            // { value: 'google', title: 'Google' },
            { value: 'twitter', title: 'Twitter' },
            { value: 'apple', title: 'Apple' },
        ];
    }, []);

    useEffect(() => {
        dispatch(getIpPhoneCodeItemAsync());
    }, [dispatch]);

    const goBack = () => {
        const redirect: any = router?.query?.redirect;
        const redirectUrl = decodeURIComponent(redirect || '');
        if (redirectUrl === '/feedback') {
            router.replace('/');
        } else {
            router.push(redirectUrl || '/');
        }
    };

    const onThirdPartyLogin = async (type: string) => {
        sessionStorage.setItem('loginType', type);
        const { facebookAppId, appleAppId, twitterAppId, googleAppId }: any = process.env.NEXT_PUBLIC_SETTING;
        if (type === 'apple') {
            location.href = `https://appleid.apple.com/auth/authorize?client_id=${appleAppId}&redirect_uri=${location.origin}/login/callback&response_type=code`;
        } else if (['google', 'twitter', 'facebook'].includes(type)) {
            // https://adodson.com/hello.js/#helloinit //
            // 配置地址 https://auth-server.herokuapp.com/#manage-apps   用twitter账号登录 使用github登录
            const hello = require('hellojs/dist/hello.all.js');
            hello.init(
                {
                    twitter: twitterAppId,
                    facebook: facebookAppId,
                    google: googleAppId,
                },
                {
                    oauth_proxy: 'https://auth-server.herokuapp.com/proxy',
                    // redirect_uri: `/login/callback`
                },
            );
            hello.login(type, {
                display: 'page',
                redirect_uri: '/login/callback',
                page_uri: false,
                force: false,
            });
        } else {
            console.log(type);
        }
    };

    return (
        <>
            <Meta />

            {/* 极验 */}
            <Script src="https://static.geetest.com/v4/gt4.js" />

            <div className={styles.wrapper}>
                <div id="captcha" />
                <div className={styles.wrapperPc}>
                    <Button className={styles.back} type="link" onClick={goBack} aria-label="Go back">
                        <NextImage src="/login/login-arrow.webp" needPrefix width={14} height={14} alt="arrow" />
                        {intl.get('Login.LoginBtn').d('登录')}
                    </Button>
                    <div className={styles.login}>
                        <NextImage src="/login/login-pc.webp" needPrefix width={193} height={133} alt="logo-pc" />

                        <Tabs
                            defaultActiveKey="1"
                            className={styles.tabs}
                            items={[
                                { label: intl.get('BindAccount.Email').d('电子邮箱'), key: '1', children: <Email /> },
                                { label: intl.get('BindAccount.Phone').d('手机号'), key: '2', children: <Mobile /> },
                            ]}
                        ></Tabs>
                        <div style={{ width: 400 }}>
                            <Divider style={{ color: '#ccc', fontSize: 14 }}>
                                {intl.get('Login.OtherLogin').d('其他登录方式')}
                            </Divider>
                        </div>

                        <div className={styles.thirdParties}>
                            {loginTypeList.map((item: any) => {
                                const { value, title } = item;
                                return (
                                    <div
                                        className={styles.thirdLogo}
                                        key={value}
                                        onClick={() => {
                                            onThirdPartyLogin(value);
                                        }}
                                        title={title}
                                    >
                                        <NextImage
                                            src={`/bind/${value}.webp`}
                                            needPrefix
                                            width={24}
                                            height={24}
                                            alt={title}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={styles.wrapperMobile}>
                    <NextImage src="/login/back.webp" needPrefix layout="fill" alt="background" />
                    <div className={styles.login}>
                        <NextImage
                            src="/login/login-mobile.webp"
                            width={153}
                            height={20}
                            needPrefix
                            alt="logo-mobile"
                        />

                        <Tabs
                            defaultActiveKey="1"
                            className={styles.tabs}
                            items={[
                                { label: intl.get('BindAccount.Email').d('电子邮箱'), key: '1', children: <Email /> },
                                { label: intl.get('BindAccount.Phone').d('手机号'), key: '2', children: <Mobile /> },
                            ]}
                        ></Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export const getServerSideProps = withImgData(() => ({ props: {} }));

Login.getLayout = (page: any) => <>{page}</>;

export default Login;
