import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input } from 'antd';
import { NextImage } from '@/components';
import { useAppDispatch } from '@/store/hook';
import { setCanScroll } from '@/store/config';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';
interface Props {
    value: any;
    open: boolean;
    form: any;
    phoneCodeList: any[];
    formatList: (l: any) => any[];
    onClose?: () => void;
    onChange?: (a: any) => void;
}

const { pleaseEnter } = formUtils;

const CodeMenu: React.FC<Props> = (props) => {
    const { value: formValue, form: phoneForm, open, phoneCodeList, formatList, onClose, onChange } = props;
    const listRef = useRef<any>(null);
    // 交易商下拉选择
    const [codeList, setCodeList] = useState<any[]>([]);
    const { current: page } = useRef({ pageSize: 5, total: 0, current: 1, loading: false, scrollTop: 0 });
    const dispatch = useAppDispatch();

    // 初始化
    useEffect(() => {
        const cList = formatList(cloneDeep(phoneCodeList || []));
        setCodeList(cList);
    }, [formatList, phoneCodeList]);

    // 自定义搜索
    const onSearchChange = debounce((e: any) => {
        const { value } = e.target;
        const newList = cloneDeep(phoneCodeList || []).filter((item: any) => {
            return item.label.includes(value);
        });
        setCodeList(formatList(newList));
    }, 200);

    const onSelItem = (item: any) => {
        const { oValue, value } = item || {};
        phoneForm?.setFieldsValue({ countryCode: oValue });
        onChange?.(value);
        onClose?.();
    };

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage width={200} height={160} src="/img/place/null_data.webp" alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Common.NoData').d('暂无数据')}～</p>
        </div>
    );

    const closePageScroll = useCallback(() => {
        document.body.style.position = '';
        document.body.style.top = '';
        // 使 scrollTop 恢复原状
        if (document?.scrollingElement) {
            document.scrollingElement.scrollTop = page.scrollTop;
        }
        dispatch(setCanScroll(true));
    }, [dispatch, page.scrollTop]);

    useEffect(() => {
        if (open) {
            dispatch(setCanScroll(false));
            page.scrollTop = document?.scrollingElement?.scrollTop || 0;
            document.body.style.position = 'fixed';
            document.body.style.top = -page.scrollTop + 'px';
        } else {
            closePageScroll();
        }

        return () => {
            closePageScroll();
        };
    }, [closePageScroll, dispatch, open, page]);

    return (
        <div ref={listRef} className={c(styles.codeMenu, open ? styles.codeMenuOpen : null)}>
            <div className={styles.searchInputBox}>
                <Input
                    placeholder={pleaseEnter(intl.get('BindAccount.SearchContent').d('请输入搜索内容'))}
                    className={styles.searchInput}
                    onInput={onSearchChange}
                    prefix={<span className={c(styles.prefix, 'iconfont', 'icon-sousuo')} />}
                />
            </div>
            {codeList.length !== 0 ? (
                <ul className={styles.groupList}>
                    {codeList.map((codeItem: any) => {
                        const { key: codeKey, list = [] } = codeItem || {};
                        return (
                            <li key={codeKey} className={styles.listGroup}>
                                <div className={styles.listGroupTit}>{codeKey}</div>
                                {list.map((item: any) => {
                                    const { value, oValue, label } = item || {};
                                    const arrLabel = (label || '').split('(');
                                    const newLabel = (arrLabel || [])[0];
                                    return (
                                        <div
                                            key={oValue}
                                            className={c(
                                                styles.listGroupItem,
                                                formValue === oValue ? styles.listGroupActive : null,
                                            )}
                                            title={label}
                                            onClick={() => {
                                                onSelItem(item);
                                            }}
                                        >
                                            <span className={styles.text}>{newLabel}</span>
                                            <span className={styles.text}>{value}</span>
                                        </div>
                                    );
                                })}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                notFoundContent
            )}
        </div>
    );
};

export default CodeMenu;
