import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useRouter } from 'next/router';
import { postLoginApi } from '@/services/auth';
import AuthCode from '@/components/public/AuthCode';
import PhoneCodeSelect from '@/components/public/PhoneCodeSelect';
import { useAppSelector } from '@/store/hook';
import { useCookies } from 'react-cookie';
import { postLoginCallback } from '@/utils/login';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';

const { pleaseEnter } = formUtils;
const { Password } = Input;

const Mobile = () => {
    const [_cookies, setCookie] = useCookies(['AUTH_TOKEN']);

    const [form] = Form.useForm();
    const selRef = useRef<any>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    const [type, setType] = useState<string>('code'); // 默认验证码登录
    const { phoneCodeItem } = useAppSelector((state) => state.config);

    const router = useRouter();

    const validateMobile = () => {
        return form?.validateFields(['mobile', 'phone_code']);
    };

    const onFinish = (values: any) => {
        setLoading(true);
        delete values.countryCode;
        postLoginApi({ ...values, login_type: type === 'code' ? 4 : 0 })
            .then((res: any) => {
                postLoginCallback(res, router, setCookie);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const toggleLoginType = () => {
        setType(type === 'code' ? 'pwd' : 'code');
    };

    const goFindPwd = () => {
        router.push('/findPwd?type=mobile');
    };

    useEffect(() => {
        const { country, phoneCode } = phoneCodeItem;
        form?.setFieldsValue({ countryCode: `${country}.${phoneCode}`, phone_code: phoneCode });
    }, [form, phoneCodeItem]);

    return (
        <div className={styles.mobile}>
            <Form
                form={form}
                autoComplete="off"
                size="large"
                className={styles.form}
                initialValues={{ phone_code: phoneCodeItem?.phoneCode }}
                onFinish={onFinish}
                onValuesChange={() => {
                    setDisabled(false);
                }}
            >
                <Form.Item
                    name="mobile"
                    rules={[
                        { required: true, message: pleaseEnter(intl.get('Setting.PhoneNum').d('请输入手机号码')) },
                        // { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/g, message: pleaseEnterCorrect(intl.get('Setting.PhoneNum').d('请输入正确的手机号码')) }
                    ]}
                >
                    <Input
                        className={styles.mobileInput}
                        bordered={false}
                        addonBefore={
                            <div className={styles.mobilePrefix} ref={selRef}>
                                <i className="iconfont icon-yonghu" />
                                <Form.Item name="phone_code" noStyle>
                                    <PhoneCodeSelect parentRef={selRef} />
                                </Form.Item>
                            </div>
                        }
                        placeholder={pleaseEnter(intl.get('Setting.PhoneNum').d('请输入手机号码'))}
                    />
                </Form.Item>
                <Form.Item name="countryCode" hidden>
                    <Input />
                </Form.Item>
                {type === 'code' && (
                    <>
                        <Form.Item
                            style={{ marginBottom: 8 }}
                            name="verificationCode"
                            rules={[
                                {
                                    required: true,
                                    message: pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码')),
                                },
                                {
                                    len: 6,
                                    message: pleaseEnter(
                                        intl.get('Common.SixVerificationCode').d('请输入6位长度的验证码'),
                                    ),
                                },
                            ]}
                        >
                            <Input
                                prefix={
                                    <span className={styles.prefix}>
                                        <i className="iconfont icon-mima" />
                                    </span>
                                }
                                placeholder={pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码'))}
                                maxLength={6}
                                type="number"
                                suffix={<AuthCode validate={validateMobile} />}
                            />
                        </Form.Item>
                        <div className={styles.pwdBtns}>
                            <span />
                            <div className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.PassLogin').d('密码登录')}
                            </div>
                        </div>
                    </>
                )}
                {type === 'pwd' && (
                    <>
                        <Form.Item
                            style={{ marginBottom: 8 }}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')),
                                },
                            ]}
                        >
                            <Password
                                autoComplete="new-password"
                                className={styles.pwd}
                                prefix={
                                    <span className={styles.prefix}>
                                        <i className="iconfont icon-mima" />
                                    </span>
                                }
                                placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                            />
                        </Form.Item>
                        <div className={styles.pwdBtns}>
                            <span className={styles.pwdLogin} onClick={goFindPwd}>
                                {intl.get('Login.ForgetPass').d('忘记密码')}
                            </span>
                            <span className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.VerifyCodeLogin').d('验证码登录')}
                            </span>
                        </div>
                    </>
                )}

                <Form.Item>
                    <Button
                        className={styles.btn}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        disabled={disabled}
                        loading={loading}
                        aria-label="Registration Or Login"
                    >
                        {intl.get('Login.RegisterLog').d('注册/登录')}
                    </Button>
                </Form.Item>
            </Form>
            <p className={styles.tip}>
                {intl
                    .get('Login.NotSupportedYet')
                    .d('* 暂不支持中国大陆手机号码登录/注册，未注册手机验证后将自动登陆～')}
            </p>
            <p className={styles.botTip}>
                {intl.get('Login.AutomaticRegistration').d('* 未注册的手机号验证通过后将自动注册')}
            </p>
        </div>
    );
};

export default Mobile;
