import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Select, Input } from 'antd';
import { NextImage } from '@/components';
import CodeMenu from './CodeMenu';
import { getPhoneCodeListAsync } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { pinyin } from 'pinyin-pro';
import c from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';
interface Props {
    value?: any;
    parentRef?: any;
    onChange?: (a?: any) => void;
}

const { pleaseEnter } = formUtils;

const { OptGroup, Option } = Select;

const PhoneCodeSelect: React.FC<Props> = (props) => {
    const { value: formValue, parentRef, onChange } = props;
    const { phoneCodeList = [] } = useAppSelector((state) => state.config);

    const [form] = Form.useForm();
    const selRef = useRef<any>(null);
    const inpRef = useRef<any>(null);

    // 当前选择的 country
    const phoneForm = Form.useFormInstance();
    const countryCode = Form.useWatch('countryCode', phoneForm);

    const [open, setOpen] = useState<boolean>(false);
    const [codeList, setCodeList] = useState<any>([]);
    const [headerArray, setHeaderArray] = useState<any>([]);

    const { current: page } = useRef({ scrollTop: 0, hasScroll: false });

    // 获取手机号地区前缀
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPhoneCodeListAsync());
    }, [dispatch]);

    const formatCodeList = (l: any = []) => {
        const obj: any = {};
        l.forEach((item: any) => {
            const { label } = item;
            const codeString = pinyin((label || '').split('')[0], { toneType: 'none' }).toUpperCase();
            const letter = (codeString || '').split('')[0] || '';
            if (obj[letter]) {
                obj[letter].push({ ...item, code: letter });
            } else {
                obj[letter] = [{ ...item, code: letter }];
            }
            return { ...item, code: letter };
        });
        return obj;
    };
    const formatLetterList = useCallback(
        (l: any = []) => {
            const obj: any = formatCodeList(l || []);
            let num = 0;
            return Object.keys(obj).map((key: string) => {
                const item = { key, list: obj[key] || [], top: num };
                num += (obj[key] || []).length + 1;
                page.scrollTop = num;
                return item;
            });
        },
        [page],
    );

    // 初始化
    useEffect(() => {
        const cList = formatLetterList(cloneDeep(phoneCodeList || []));
        setCodeList(cList);
        const hList = cList.map((item: any) => {
            return item.key;
        });
        setHeaderArray(hList);
    }, [formatLetterList, phoneCodeList]);

    const onGoToHref = (v: any) => {
        selRef?.current?.scrollTo(0);
        const itemObj = codeList.find((item: any) => {
            return item.key === v;
        });
        const { top } = itemObj || {};
        if (top <= page.scrollTop) {
            selRef?.current?.scrollTo(top);
        } else {
            selRef?.current?.scrollTo(top + 6);
        }
        page.scrollTop = top;
    };

    const onDropdownVisibleChange = (v: boolean) => {
        setOpen(v);
        if (v) {
            onSearchChange({ target: { value: '' } });
        }
        form?.setFieldsValue({ searchVal: '' });
    };

    // 自定义搜索
    const onSearchChange = debounce((e: any) => {
        const { value } = e.target;
        const newList = cloneDeep(phoneCodeList || []).filter((item: any) => {
            return item.label.includes(value);
        });
        setCodeList(formatLetterList(newList));
    }, 200);

    // 数据改变
    const onValueChange = (v: any) => {
        const newValue = (v || '').split('.')[1] || '';
        form?.setFieldsValue({ searchVal: '' });
        phoneForm?.setFieldsValue({ countryCode: v });
        onChange?.(newValue);
    };

    // 移动端
    const [codeOpen, setCodeOpen] = useState<boolean>(false);
    const onShowCode = () => {
        setCodeOpen(true);
    };
    const onCodeClose = () => {
        setCodeOpen(false);
    };

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage width={150} height={120} src="/img/place/null_data.webp" alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Common.NoData').d('暂无数据')}～</p>
        </div>
    );

    return (
        <>
            <Select
                value={codeList.length !== 0 ? countryCode : undefined}
                notFoundContent={notFoundContent}
                className={c(styles.codeSelect, open ? styles.codeSelectOpen : null)}
                onChange={onValueChange}
                onDropdownVisibleChange={onDropdownVisibleChange}
                ref={selRef}
                // open
                listHeight={310}
                dropdownMatchSelectWidth={400}
                defaultActiveFirstOption={false}
                getPopupContainer={() => {
                    return parentRef?.current;
                }}
                suffixIcon={<span className={c(styles.suffix, 'iconfont', 'icon-arrow-down')} />}
                optionLabelProp="label"
                popupClassName={styles.popupClass}
                dropdownRender={(menu) => (
                    <div className={styles.selectDropdown}>
                        <div className={styles.selectDropdownBack}>
                            <div className={styles.triangle} />
                            <Form form={form} className={styles.form}>
                                <Form.Item noStyle name="searchVal">
                                    <Input
                                        ref={inpRef}
                                        bordered={false}
                                        autoFocus
                                        className={styles.searchInput}
                                        onInput={onSearchChange}
                                        placeholder={pleaseEnter(
                                            intl.get('BindAccount.SearchContent').d('请输入搜索内容'),
                                        )}
                                        prefix={<span className={c(styles.prefix, 'iconfont', 'icon-sousuo')} />}
                                    />
                                </Form.Item>
                            </Form>

                            <div className={styles.listBox}>
                                {menu}
                                {codeList.length !== 0 ? (
                                    <div className={styles.aHref}>
                                        {headerArray.map((item: any) => {
                                            return (
                                                <p
                                                    key={item}
                                                    onClick={() => {
                                                        onGoToHref(item);
                                                    }}
                                                >
                                                    {item}
                                                </p>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            >
                {codeList.map((codeItem: any) => {
                    const { key: codeKey, list = [] } = codeItem || {};
                    return (
                        <OptGroup key={codeKey} label={codeKey} className={styles.listGroup}>
                            {list.map((item: any, index: number) => {
                                const { label, oLabel, oValue } = item || {};
                                return (
                                    <Option
                                        value={oValue}
                                        label={oLabel}
                                        key={oValue}
                                        className={c(
                                            styles.selOption,
                                            index === list.length - 1 ? styles.selOptionBorder : null,
                                        )}
                                        title={label}
                                    >
                                        <span className={c(styles.text, 'text')}>{label}</span>
                                    </Option>
                                );
                            })}
                        </OptGroup>
                    );
                })}
            </Select>
            <div className={styles.mobileVal} onClick={onShowCode}>
                <span>{`+${formValue}`}</span>
                <span className={c(styles.suffix, 'iconfont', 'icon-arrow-down')} />
            </div>
            <CodeMenu
                value={countryCode}
                open={codeOpen}
                form={phoneForm}
                phoneCodeList={phoneCodeList}
                formatList={formatLetterList}
                onChange={onChange}
                onClose={onCodeClose}
            />
        </>
    );
};

export default PhoneCodeSelect;
